import M$plugin$Incicarto from '/home/alvaroramirez/Escritorio/API-CNIG/api-ign-js/src/plugins/incicarto/src/facade/js/incicarto';
import M$control$IncicartoControl from '/home/alvaroramirez/Escritorio/API-CNIG/api-ign-js/src/plugins/incicarto/src/facade/js/incicartocontrol';
import M$impl$control$IncicartoControl from '/home/alvaroramirez/Escritorio/API-CNIG/api-ign-js/src/plugins/incicarto/src/impl/ol/js/incicartocontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.Incicarto = M$plugin$Incicarto;
window.M.control.IncicartoControl = M$control$IncicartoControl;
window.M.impl.control.IncicartoControl = M$impl$control$IncicartoControl;
